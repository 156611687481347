.font-text {
    font-size: 1.5rem;
    font-weight: 700;
}

.image-center-one {
    width: 35%;
    margin-bottom: 10%;
}

.image-center {
    width: 25%;
    margin-bottom: 5%;
}

.display-setter-one{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 92%;
    background-color: #fff;
}

.display-setter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.font-text-one{
    color: #000;
    font-weight: 700;
    font-size: 35px;

}