.section-nine{
    float: left;
    clear: both;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
}
.image-gallery-bullets {
    left: 0 !important;
    position: relative!important;
    right: 0!important;
    background: #f1f1f1!important;
    line-height: 4rem!important;
    margin: 0 auto !important;
    bottom: 0 !important;
    width: 95% !important;
}
.image-gallery-slide {
    width: 100%;
}

.image-gallery-bullets .image-gallery-bullet.active {
    transform: scale(1.2) !important;
    border: 1px solid #686464 !important;
    background: #eb3239 !important;
    box-shadow: none !important;
    padding: 6px !important;
}

.image-gallery-bullets .image-gallery-bullet{
    transform: scale(1.2) !important;
    border: 1px solid #686464 !important;
    background: #f1f1f1 !important;
    box-shadow: none !important;
    padding: 6px !important;
}

.originalClassnew .image-gallery .image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
    width: auto !important;
}

/* .originalClassnew .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide {
    height: 15rem;
} */
.originalClassnew .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide {
    height: auto !important;
}
.image-gallery-slide

.originalClassnew .image-gallery {
    
     width: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (min-width:414px) {
    .originalClassnew .image-gallery {
       
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width:768px) {
    .originalClassnew .image-gallery {
       
       
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.image-gallery-thumbnail-image{
    width: 100% !important;
    height: 120px !important;
    background: #fff;
    padding: 4px;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container{
    align-items: unset;
    justify-content: unset;
    display: flex;
}


.image-gallery-right-nav{
    display: none;
}

.image-gallery-left-nav{
    display: none;
}

.image-gallery-icon.image-gallery-fullscreen-button{
    display: none;
}
.image-gallery-slides div img{
     width: 100%;
     height: 100%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 150px !important;
}

.image-gallery-slide {
    height: 150px;
}

@media (max-width: 414px){
.image-gallery-slide {
    height: auto;
}}

.image-gallery-slides {
    background: transparent;
    padding: 15px;
}
.image-gallery-thumbnails {
    overflow: visible !important;
    padding: 0 !important;
}

.image-gallery-thumbnail-inner{
    min-height: 335px;
}

.image-gallery-thumbnail-inner img{
    width: 100%;
    height: 100%;
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 0 !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 1px dashed #eb3239 !important;
    box-shadow: 0 0 3px #e1d6d6 !important;
    border-radius: 4px;
    background: #e1e1e1;
}



.image-gallery-thumbnail {
    flex: 1 0 21%;
    border: 1px solid #9b9b9b !important;
    margin: 2%;
}

@media (max-width: 768px){
.image-gallery-thumbnail {
    border: 3px solid transparent;
     width: auto !important; 
     flex: 1 0 50%;
}}



.image-gallery-thumbnail-label {
    box-sizing: border-box; 
    color: #333 !important;
    font-size: 33px !important;
    left: 0 !important;
    line-height: 1em !important;
    padding: 5% !important;
    position: absolute !important;
    text-shadow: none !important;
    white-space: normal !important;
    width: 100% !important;
    font-family: "Playfair Display", serif !important;



    top: 8rem !important;
    transform: initial !important;
    background: transparent;
    height: 31%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    touch-action: pan-y;
    margin-top: 8px !important;
}


.piechart{
    padding: 25px;
}
.piechart canvas{
    overflow: visible !important;
    width: 100% !important;
}
.canvasInd canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
    padding: 25px !important;
  }

@media (max-width:2565px) and (min-width:1440px) {
    .canvasInd canvas {
        margin: 0 auto;
        padding: 17% !important;
      }
     
}


svg.charts {
    width: 18rem;
    margin-top: 3rem;
}
.token_list{
    white-space: break-spaces;
    font-variation-settings: "wght" 400;
    background: #e9d5d8;
    min-height: 6rem;
    margin-top: 2rem;
    padding: 8px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 1.7px 0.8px 7px 0 rgb(157 157 157 / 30%);
    color: #464545 !important;
    font-size: 0.999rem !important;
    overflow-x: auto !important;
    text-align: justify !important;
    line-height: 23px !important;
}


.boxify-chart{
   
    display: flex;
    align-items: center;
    justify-content: center;
}

.per_float{
    height: 33px;
    width: 33px;
    position: absolute;
    bottom: -4px;
    padding: 5px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background: #e9d5d8;
    font-size: 12px;
    right: -4px;
    font-weight: bold;
    border-bottom: 1px solid #c7c0c1;
    border-right: 1px solid #c7c0c1;
}


.fortokens.tok1{
    border-color: rgb(194 227 249);
    background-color: rgb(255, 51, 51);
}



.fortokens.tok2{
    border-color: rgb(255 208 218);
    background-color: rgb(255, 117, 117);
}

.fortokens.tok3{
    border-color: rgb(255, 206, 86);
    background-color: rgba(255, 149, 149);
}


.fortokens.tok4{
    border-color: rgba(167, 0, 0, 0.6);
    background-color: rgba(185, 51, 51);
}


.fortokens.tok5{
    border-color: rgb(153, 102, 255);
    background-color: rgba(201, 133, 133);
}

.fortokens.tok6{
    border-color: rgb(252 223 195);
    background-color: rgba(231, 97, 97);
}






.fortokensd.tok1{
    border-color: rgb(194 227 249);
    background-color: rgb(255, 51, 51);
}



.fortokensd.tok2{
    border-color: rgb(255 208 218);
    background-color: rgb(255, 117, 117);
}

.fortokensd.tok3{
    border-color: rgb(255, 206, 86);
    background-color: rgba(255, 149, 149);
}


.fortokensd.tok4{
    border-color: rgba(167, 0, 0, 0.6);
    background-color: rgba(185, 51, 51);
}


.fortokensd.tok5{
    border-color: rgb(153, 102, 255);
    background-color: rgba(201, 133, 133);
}

.fortokensd.tok6{
    border-color: rgb(252 223 195);
    background-color: rgba(231, 97, 97);
}

















.tokenhd_div{
    margin: auto;
}
.flexifd{
    display: inline-block;
    width: 70px;
    padding-left: 8px;
}

@media (min-width: 768px){
    .flexifd{
        display: inline-block;
        width: 58px;
        padding-left: 8px;
    }
    
}

@media (min-width:992px){
    .flexifd{
        display: inline-block;
        width: 58px;
        padding-left: 8px;
    }
    
}


@media (min-width: 1200px){
    .flexifd{
        display: inline-block;
        width: 70px;
        padding-left: 8px;
    }
    
}


@media (min-width: 1600px){
    .flexifd{
        display: inline-block;
        width: 70px;
        padding-left: 8px;
    }
    
}



.flexifd div:nth-child(1)::after{
   content: '%';
   position: relative;
}

.borbutmn{
    height: 38px;
    border: 1px solid;
}



.token_text{
    font-size: 1.1rem;
    line-height: 28px;
    text-align: justify;
    color: #6e6e6e;
}

.chart_text_div{
    margin: 2rem auto;
}

@media (max-width: 850px){
    .per_float {
        height: 3.5rem;
        width: 3.5rem;
        top: -2rem;
        left: -10px;
    }
}
