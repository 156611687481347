.sectionBack {
    background-image: repeating-linear-gradient(90deg, hsla(64, 83%, 54%, 0.05) 0px, hsla(64, 83%, 54%, 0.05) 1px, transparent 1px, transparent 11px, hsla(64, 83%, 54%, 0.05) 11px, hsla(64, 83%, 54%, 0.05) 12px, transparent 12px, transparent 32px), repeating-linear-gradient(135deg, hsla(64, 83%, 54%, 0.05) 0px, hsla(64, 83%, 54%, 0.05) 1px, transparent 1px, transparent 11px, hsla(64, 83%, 54%, 0.05) 11px, hsla(64, 83%, 54%, 0.05) 12px, transparent 12px, transparent 32px), repeating-linear-gradient(45deg, hsla(64, 83%, 54%, 0.05) 0px, hsla(64, 83%, 54%, 0.05) 1px, transparent 1px, transparent 11px, hsla(64, 83%, 54%, 0.05) 11px, hsla(64, 83%, 54%, 0.05) 12px, transparent 12px, transparent 32px), repeating-linear-gradient(180deg, hsla(64, 83%, 54%, 0.05) 0px, hsla(64, 83%, 54%, 0.05) 1px, transparent 1px, transparent 11px, hsla(64, 83%, 54%, 0.05) 11px, hsla(64, 83%, 54%, 0.05) 12px, transparent 12px, transparent 32px), linear-gradient(135deg, rgb(50, 127, 244), rgb(235, 50, 57));



}

.cnTcfspan {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 15px;
    width: 100%;
    display: block;
    position: relative;
    z-index: 99;
}


.navbar-nav li p {
    font-weight: 600;
    position: absolute;
    left: 65px;
    text-transform: uppercase;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
}

.navbar-nav li:hover p {
    visibility: visible;
    opacity: 1;
    padding: 10px 10px 6px;
    background-color: #fff;
    color: #333;
    border-radius: 5px;
    box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
}


.gamingd {
    position: absolute;
    top: 0;
    z-index: 9;
    width: 50rem;
    right: 0;
}

.gamingd img {
    width: 100%;
}

.mrGinsdf {
    margin: 1rem;
    padding: 2.8rem;
}

.sectionBack div:nth-child(2) {
    position: absolute;
    top: 0;
}


@media (max-width:720px) {
    /* .mobileDxs {
        margin-top: 25rem;
    } */

    .mrGinsdfTopd {
        display: none;
    }

    .sectionBack div:nth-child(2) {
        display: none !important;
    }


}


@media (max-width:328px) {
    /* .mobileDxs {
        margin-top: 30rem;
    } */

    .p-52 {
        padding: 14px !important;
    }

    h1 {
        font-size: 2rem;
    }

    .sectionBack div:nth-child(2) {
        display: none !important;
    }




}

.mrGinsdfTopd {
    height: 18rem;
}

.headnc {
    color: #333;
    font-weight: 700;
    text-align: justify;
}

.kjz {
    color: #333;
    font-weight: 500;
    text-align: justify;
}

.link-fink{
    color: #e53939;
    text-decoration: underline;
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) ;
}

.rounded {
    border-radius: 0.25rem ;
}

.bg-white {
    background-color: #fff;
}

.p-52 {
    padding: 2rem !important;
}

.gamingstxt {
    color: #4b4b4d;
    font-family: Rajdhani, sans-serif;
    font-weight: 600;
    padding: 2em 0em;
    position: relative;
    z-index: 99999;
    font-size: 4rem;
    line-height: 1;
    height: 100%;
    text-align: center !important;
}

@media (max-width: 768px) {
    .gamingstxt {
        text-align: center;
        color: #4b4b4d;
        font-family: 'Rajdhani', sans-serif;
        font-weight: 700;
        padding: 0em 2em;
        position: relative;
        z-index: 99999;
        font-size: 3rem;
    }
}

.sharowRw {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.gamings {
    position: absolute;
    right: 0;
}

.gamings {
    top: -8rem;
    width: 100%;
}

@media (min-width:320px) and (max-width:915px) {
    .gamings {
        top: 5rem;
        width: 100%;
    }
}




@media (min-width: 1440px) {
    .gamings {
        width: 58%;
        right: 0;
        top: -28%;
    }
}

.gamings24 {
    position: absolute;
    width: 61%;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 38%;
}

@media (min-width: 1440px) {
    .gamings24 {
        position: absolute;
        width: 45%;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}





li.adactive {
    list-style: none;
    margin-top: 2rem;
    font-family: 'Rajdhani', sans-serif;
    ;
}

.actives {
    display: block;
}

li.adactive:hover button {
    color: #333;
}


.adactive button p {
    display: none;
    font-family: 'Rajdhani', sans-serif;
    ;
    text-align: justify;
}


.adactive button.actives p {
    display: block;
    font-family: 'Rajdhani', sans-serif;
    ;

    float: left;
    width: 100%;
    text-align: justify;
}

.adactive button {
    background: #feb100;
    display: block;
    padding: 1rem;
    border-radius: 8px;
    width: 100%;
}




.adactive button.actives {
    background: #fff;
    display: block;
    border: 1px solid #feb100;
    border-radius: 5px;
    padding: 1rem;
}

.adactive button.actives h2 {
    color: #3d3d3d;
}

.layouthomedx h2 {
    color: #fff;
    position: relative;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    list-style: circle;
    font-size: 1.5rem;

}

.butonsbtns1 {
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 11px 5px;
}

.butonsbtns1 img {
    max-width: 100%;
}

@media (max-width: 768px) {
    .layouthomedx h2 {

        color: #fff;
        position: relative;
        font-family: 'Rajdhani', sans-serif;
        ;
        font-weight: 700;
        list-style: circle;
    }

}

@media (min-width: 1600px) {
    .layouthomedx h2 {

        color: #fff;
        position: relative;
        font-family: 'Rajdhani', sans-serif;
        ;
        font-weight: 700;
        list-style: circle;
    }

}




.footer_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}


.metaverses {
    position: relative;
    right: 0;
    width: auto;
    z-index: 9;
    top: 0;
    opacity: 0.3;
}

@media (max-width: 768px) {
    .metaverses {
        position: relative;
        right: 0;
        width: auto;
        z-index: 9;
        top: 0;
        opacity: 0.3;
    }
}


@media (min-width: 1600px) {
    .metaverses {
        position: relative;
        right: 0;
        width: auto;
        z-index: 9;
        top: 0;
        opacity: 0.3;
    }
}


.telegram {
    padding: 0;
    padding-top: 0rem;
    position: relative;
    top: 26px;
}

.telegram img {
    width: 25px;
    position: relative;
    top: 17px;
    left: 17px;
}


.aboutfts {
    color: #ffffff;
    letter-spacing: 0px;
    text-align: justify;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;

}

@media (max-width: 768px) {
    .aboutfts {
        font-family: 'Rajdhani', sans-serif;
        font-weight: 600;

    }
}



.footer_imgs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_imgs img {
    margin: 1rem;
}


.section-footer {
    margin-top: 12rem;
    background-repeat: no-repeat;
    height: auto;
    float: left;
    width: 100%;
    padding-top: 3em;
    padding-bottom: 2em;
    position: relative;
    z-index: 9999;
    background: #F8F8F8;
}

.boxeds {
    padding-top: 0 !important;
    width: 200px;
    padding-bottom: 0 !important;
    padding: 1rem !important;

}

@media (min-width: 1200px) {

    .boxeds {
        padding-top: 0 !important;

        padding-bottom: 0 !important;
        padding: 1rem !important;
        width: 100% !important;
    }


}


@media (min-width: 992px) {
    .boxeds {
        padding-top: 0 !important;

        padding-bottom: 0 !important;
        padding: 1rem !important;
        width: 100% !important;
    }

}


@media (min-width: 768px) {
    .boxeds {
        padding-top: 0 !important;

        padding-bottom: 0 !important;
        padding: 1rem !important;
        width: 100% !important;
    }

}




.knoDfgsw {
    position: relative;
    top: 0;
    text-align: center;
    width: 100%;
    color: #1a1a1a;
    padding-top: 0;
    padding-bottom: 15px;
}


@media (min-width: 1200px) {
    .knoDfgsw {
        position: relative;
        top: -19px;
        text-align: center;
        width: 100%;
        color: #f1f1f1;
        padding-top: 0em;
    }

    .boxeds {
        padding-top: 0 !important;

        padding-bottom: 0 !important;
        padding: 1rem !important;
        width: 100% !important;
    }

    .section-footer {
        margin-top: 12rem;
        background: transparent;
        padding: 5rem 4rem;
        float: left;
        width: 100%;
        background-size: contain;
        background-size: 100% 100%;
        position: relative;
        z-index: 9999;
        background: #F8F8F8;

    }
}

@media (max-width: 768px) {
    .section-footer {
        margin-top: 0rem;
        background: transparent;
        padding: 2rem 2rem;
        float: left;
        width: 100%;
        background-size: contain;
        background-size: 100% 100%;
        position: relative;
        z-index: 9999;
        background: #F8F8F8;
    }
}



@media (min-width: 992px) {
    .knoDfgsw {
        position: relative;
        top: -19px;
        text-align: center;
        width: 100%;
        color: #f1f1f1;
        padding-top: 0em;
    }

    .boxeds {
        padding-top: 0 !important;

        padding-bottom: 0 !important;
        padding: 1rem !important;
        width: 100% !important;
    }

    .section-footer {
        margin-top: 12rem;
        background: transparent;
        padding: 5rem 4rem;

        background-size: contain;
        background-size: 100% 100%;
        position: relative;
        z-index: 9999;
        background: #F8F8F8;
    }
}

@media (min-width: 768px) {
    .knoDfgsw {
        position: relative;
        top: 0;
        text-align: left;
        font-family: 'Rajdhani', sans-serif;
        ;
        width: 100%;
        color: #1a1a1a;
        padding-top: 2em;
        padding-bottom: 15px;
    }

    .boxeds {
        padding-top: 0 !important;

        padding-bottom: 0 !important;
        padding: 0 !important;
        width: 17rem !important;
        margin-top: 1.3rem;
    }

    .section-footer {
        margin-top: 0;
        float: left;
        width: 100%;
        padding: 0rem 4rem;
        background-size: contain;
        background-size: 100% 100%;
        padding-top: 2rem;
        position: relative;
        z-index: 9999;
        background: #F8F8F8;
    }
}

.social-menu ul li a i {
    color: #fff !important;
}

/*
.social-menu ul{
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
    display: flex;
}

.social-menu ul li{
    list-style: none;
    margin: 0 15px;
}

.social-menu ul li .fab{
    font-size: 30px;
    line-height: 60px;
    transition: .3s;
    color: #000;
}

.social-menu ul li .fab:hover{
    color: #fff;
}

.social-menu ul li a{
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    transition: .6s;
    box-shadow: 0 5px 4px rgba(0,0,0,.5);
}

.social-menu ul li a:hover{
    transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) a:hover{
    background-color: rgba(0, 0, 0, 0.829);
}
.social-menu ul li:nth-child(2) a:hover{
    background-color: #E4405F;
}
.social-menu ul li:nth-child(3) a:hover{
    background-color: #0077b5;
}
.social-menu ul li:nth-child(4) a:hover{
    background-color: #000;
}



 */



















ul.boxify {
    display: flex;
    align-items: self-start;
    justify-content: flex-end;

}


ul.boxify li {
    list-style: none;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    transition: .6s;
    box-shadow: 0 5px 4px rgb(0 0 0 / 50%);
    position: relative;
    z-index: 99999999999999;
    cursor: pointer;
    padding-top: 0.7em;
    margin-left: 5px;
}

ul.boxify li a i {
    font-size: 30px;
    color: #000000;
    padding-top: 0.18em;
}

.social-menu {
    float: right;
    align-items: self-start;
    justify-content: space-between;
}



.social-menu ul {

    top: 0%;
    transform: translate(0, 0);
    text-align: center;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    padding-top: 2rem;
}

@media (min-width: 1200px) {

    .social-menu ul {

        top: 160%;
        transform: translate(0, 160%);

        text-align: center;
        margin: 0;
        padding: 0;
        left: 0;
        right: 0;
        background: transparent;
    }
}

@media (min-width: 992px) {
    .social-menu ul {

        top: 160%;
        transform: translate(0, 160%);

        text-align: center;
        margin: 0;
        padding: 0;
        left: 0;
        right: 0;
        background: transparent;
    }
}

@media (min-width: 768px) {
    .social-menu ul {

        top: 0;
        transform: translate(0, 35%);
        text-align: center;
        margin: 0;
        padding: 0;
        left: 0;
        right: 0;
        background: transparent;
        /* display: flex; */
    }

}

@media (min-width: 1600px) {
    .boxeds {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding: 1rem !important;
        width: 24rem !important;
        margin-top: 1.3rem;
    }
}


.social-menu ul li {
    list-style: none;
    display: inline-block;
    margin: 0rem 1rem 1rem;
    width: 65px;
    height: 65px;
    position: relative;
}

.social-menu ul li a {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    line-height: 61px;
    text-align: center;
    font-size: 1.8em;
    transition: .9s;
    transition-delay: .5s;
    position: relative;
    left: -1px;
}

.social-menu ul li:hover a {
    background: #4b4b4d;
    position: relative;
    z-index: 9999999999999999;
    box-shadow: rgba(0 0 0 / 35%) 0px 5px 15px;
}


.social-menu ul li:hover a i {
    color: #f1f1f1;

}




.social-menu ul li .box {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0px solid #000;
    transition: .5s;

}

.social-menu ul li:hover .box {
    transform: rotate(45deg);
}

.social-menu ul li .box span {
    position: absolute;
    background: #4b4b4d;
    transition: .9s;
    transition-delay: .5s;
}

.social-menu ul li:hover .box span {
    background: #bb0c18;
}

.social-menu ul li .box span:nth-child(1) {
    width: 20px;
    height: 5px;
    top: -2px;
    left: -2px;
}

.social-menu ul li:hover .box span:nth-child(1) {
    width: 117%;
}

.social-menu ul li .box span:nth-child(2) {
    width: 5px;
    height: 20px;
    top: -2px;
    left: -2px;
}

.social-menu ul li:hover .box span:nth-child(2) {
    height: 117%;
}

.social-menu ul li .box span:nth-child(3) {
    width: 20px;
    height: 5px;
    bottom: -2px;
    right: -2px;
}

.social-menu ul li:hover .box span:nth-child(3) {
    width: 117%;
}

.social-menu ul li .box span:nth-child(4) {
    width: 5px;
    height: 20px;
    bottom: -2px;
    right: -2px;
}

.social-menu ul li:hover .box span:nth-child(4) {
    height: 117%;
}




.footerbx h6 {

    text-transform: capitalize;
    color: #ffffff;
    letter-spacing: 0px;
    text-align: left;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;
}

.footerbx ul li a {
    color: #fff;
    letter-spacing: 0px;
    text-align: justify;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;

}

@media (max-width: 768px) {
    .footerbx ul li a {
        color: #fff;
        letter-spacing: 0px;
        text-align: justify;
        font-family: 'Rajdhani', sans-serif;
        font-weight: 500;

    }

    .footerbx h6 {

        text-transform: capitalize;
        color: #ffffff;
        letter-spacing: 0px;
        text-align: left;
        font-family: 'Rajdhani', sans-serif;
        font-weight: 500;
    }

}





.footerbx ul li {
    list-style: inside;
}










.contactus {
    font-family: 'Rajdhani', sans-serif;
    ;

}

@media (max-width: 768px) {
    .contactus {
        font-family: 'Rajdhani', sans-serif;
        ;

    }
}

@media (min-width:1600px) {
    .contactus {
        font-family: 'Rajdhani', sans-serif;
        ;

    }
}

.contactus {
    position: relative;
    z-index: 9;
    box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, .15) !important;
    padding: 3rem 2rem 4rem;
    border-radius: 13px;
    border: 1px solid #f3e0e0;
}


.contactus label {
    display: block;
    margin: 5px 0 0;

    color: #817f7f;
    position: relative;
    font-family: 'Rajdhani', sans-serif;
    ;
    font-weight: 600;
    list-style: circle;
}

/* single-line text, checkbox, and button */
.contactus input,
select,
textarea {
    display: block;
    width: 100%;
    padding: 0.3em;
    background-color: #fbfbfb;
    border-bottom: solid 1px #504f4d !important;
    resize: vertical;
    border-radius: 0;
    border: none;
}

.contactus textarea {
    min-height: 110px;
    border-bottom: solid 1px #504f4d;
    border-top: none;
    border-left: none;
    border-right: none;
    resize: none;
}

.contactrade25 {
    width: 100%;
    position: relative;
    right: 1%;
    margin-top: -2%;
}




.contactus form {
    padding-left: 0;
    padding-right: 0;
}

@media (max-width: 768px) {


    .contactrade {
        bottom: 0;
        position: relative;
        width: 100%;
    }


    .contactus form {
        padding-left: 5%;
        padding-right: 5%;
    }
}


@media (min-width: 1600px) {



    .contactrade {
        width: 45vw;
        position: absolute;
        padding-top: 6%;
        right: 1%;
    }

    .contactus textarea {
        max-height: 160px;
        border-bottom: solid 1px #504f4d;
        border-top: none;
        border-left: none;
        border-right: none;
        resize: none;
        height: 100%;
    }


    .contactus form {
        padding-left: 0;
        padding-right: 0;
    }
}

.contactus textarea:focus {
    outline: none;
}

.contactus select {
    color: indigo;
}

.contactus select option {
    color: blue;
    background: lavenderBlush;
}

.contactus input[type=checkbox] {
    display: inline;
    width: auto;
    color: red;
}

.contactus input[type=submit] {
    background: #feb100;
    margin: 1em 0 0;
    color: white;
    border: none;
    border-radius: 8px;
    transition: all .3s ease-out;
}

.contactus input:focus,
.contactus input:hover,
.contactus select:focus,
.contactus select:hover,
.contactus textarea:focus,
.contactus textarea:hover {
    background: lavenderBlush;
}

/* hover and focus states */
.contactus input[type=submit]:hover,
.contactus input[type=submit]:focus {
    background: lightgreen;
    outline: none;
}

@media screen and (min-width:600px) {

    /*  make the form 2 columns */
    .contactus form:after {
        content: '';
        display: block;
        clear: both;
    }

    .contactus .column {
        width: 50%;
        padding: 1em;
        float: left;
    }
}