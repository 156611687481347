
.bidBoxify{
    padding: 4% 4%;

}

.bidBoxify25{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bg-whpds {
    float: left;
    width: 100%;
    height: 100%;
}


.Carousel45{
    width: 100% !important;
      height: 100% !important;
}
.Carousel45 div {
    width: 100%;
    height: 100% !important;
    align-items: center;
   
}


.Carousel45 div button{
    background: #e83138 !important;
    color: #fff;
}


.bidBoxify25 {
    background: #880a0a !important;
    box-shadow: 0px 10px 40px rgba(0,0,0,0.2) !important; 
    padding: 5% 5%;
    border-radius: 12px;
}

.bidBoxify25 h5{
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    color: #eb3239;
    padding-bottom: 10px;
}
.bg-whpds div div:nth-child(2){
    align-items: center;
    justify-content: center;
    margin-top: 0;
    height: auto !important;
}

.rec-dot.rec.rec-dot_active{
    background: #ffffff !important;

}

.rec-dot_activ{
    background: #333 !important;
}


.bidBoxify25 p{
    height: 100%;
}

.Carousel45 div div div{
    position: relative  !important;
}



.collumns .Carousel45 .rec > div{
    background-color: transparent;
}


.react-3d-carousel .slider-container .slider-content .slider-single.active{
opacity: 1 !important;
background: #fff !important;
}


.fufiimgx img {
    max-width: 100%;
}

.bg-whpds div section div {
    background: #fff !important; 
    opacity: 1 !important;
}


.slider-single-content{
    padding: 2rem 2rem;
    border-radius: 15px;
    min-height: 350px;
}
[class*=smol]:not([data-component]) > *:not([data-unstyled]) {
    border: 0px dashed !important;
}


.slider-single-content div span:nth-child(1){
    text-align: justify !important;
    display: block;
    font-size: 28px;
    text-transform: capitalize;
    color: #e23939;
    letter-spacing: 0px;
    text-align: left !important;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 700;
    line-height: 1.75;
}

.slider-single-content div span:nth-child(2){
    text-align: justify !important;
    display: block;

    text-transform: capitalize;
    color: #4b4b4d;
    letter-spacing: 0px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;
    line-height: 1.75;
}








.react-3d-carousel .slider-container .slider-right div{
  
    color: #fafafa !important;
    border-top: 0px solid #000000 !important;
    border-right: 0px solid #000000 !important;
    border-bottom: 0px solid #000000 !important;
    border-left: 0px solid #000000 !important;
    background: #eb3239 !important;
}

.react-3d-carousel .slider-container .slider-left div{
  
    color: #fafafa !important;
    border-top: 0px solid #000000 !important;
    border-right: 0px solid #000000 !important;
    border-bottom: 0px solid #000000 !important;
    border-left: 0px solid #000000 !important;
    background: #eb3239 !important;
}


.fufiimgx{
    margin: 0 auto;
    position: relative;
    z-index: -1;
}

.bidBoxify25 {
    width: 150px;
    margin: 0 auto;
}
.bidBoxify25.bd256{
    background: #fff !important;
    box-shadow: none !important;
}

.bidBoxify25{
    background: #fff !important;
}

.bidBoxify25 img{
    width: 100%;
     height: 83px;
}
.bidBoxify25.bd256 img{
    width: 100%;
     height:65px;
}


@media (max-width: 414px){  
    .bidBoxify25 img{
        width: 100%;
        height: auto;
    }
}
@media (max-width: 500px){  
.bidBoxify25.bd256 img{
     height:auto;
}
}

@media (min-width: 768px) and (max-width: 1201px) {  
    .fufiimgx{
        padding: 0;
    }
}




.coinmics{
    font-weight: 500;
    text-align: center !important;
    color: #333;
    padding-top: 0;
    padding-bottom: 2rem;
    font-size: 16px;
}

.fufiimgx img{
width: 100%;
}


@media (max-width: 768px){

    .slider-single-content div span:nth-child(1){
        text-align: left  !important;
        display: block;
        
    }

    .slider-single-content div span:nth-child(2){
        text-align: justify !important;
        display: block;
   
    }

}

.bxshadow{
    height: 100%;
}


.img-box {
    align-items: center;
    background: #fff;
    border-radius: 2em;
    box-shadow: 0 3.4px 2.7px rgb(0 0 0 / 1%), 0 8.7px 6.9px rgb(0 0 0 / 1%), 0 17.7px 14.2px rgb(0 0 0 / 1%), 0 0.5px 7.2px rgb(0 0 0 / 13%), 0 5px 11px rgb(0 0 0 / 4%);
    display: flex;
    justify-content: center;
    min-height: 21em;
}

.img-box img{
    width: 13rem;
    padding: 1px;
    margin: 0px auto;
}

@media (max-width: 820px){
    .img-box {
        min-height: auto;
    }
    .img-box img{
        width: 10rem;
    }}

@media (max-width: 500px){
.img-box {
    min-height: auto;
}
.img-box img{
    width: 8rem;
}}