
.ps-bot ul{
  margin: 0;
  padding: 0;
}
.ps-top ul{
  margin: 0;
  padding: 0;
}
.psTimeline::marker{
  display: none;
  font-size: 0px;
}


.ps-sp-top.top1{
  background: #3e3b5a !important;
  color: #fff !important;
  top: -40px !important;
}

.ps-sp-bot.top2{
  background: #e6b05b !important;
  color: #fff !important;
}

.ps-sp-top.top3{
  background: #ed674e !important;
  color: #fff !important;
  top: -40px !important;
}

.ps-sp-bot.top4{
  background: #41beab !important;
  color: #fff !important;
}

.ps-timeline-sec {
  position: relative;
  width: 100%;
}
.ps-timeline-sec .container {
  position: relative;
}














@media screen and (max-width: 767px) {
  /* .ps-timeline-sec .container ol.ps-timeline li.psTimeline{
    margin-bottom: 3rem;
  } */


  .ps-timeline-sec .container ol:before {
    display: none;
    background: #333;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 130px !important;
    left: 36px !important;
  }
  .ps-timeline-sec .container ol:after {
    background: #333;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: inherit !important;
    left: 36px;
  }
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 10px 0 !important;
    border-left: 0px solid #333;
    padding-left: 0 !important;
    padding-top: 0px !important;
    border-top: 0 !important;
    margin-left: 25px !important;
    float: left;
    width: 100%;
    height: 100%;
  }


  .ps-timeline-sec .container ol.ps-timeline li:nth-child(2) .img-handler-bot img {
    width: 70px;
  }

  .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
    bottom: 40px !important;
    width: 40% !important;
    margin-left: 25px !important;
    margin-top: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot img {
    width: 100%;
  }

  .ps-timeline-sec .container ol.ps-timeline li span {
    left: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute !important;
    bottom: 150px !important;
    width: 30% !important;
    float: left !important;
    margin-left: 35px !important;
    margin-bottom: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    margin: 0 auto !important;
    width: 80% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute !important;
    bottom: 115px !important;
    width: 30% !important;
    float: left !important;
    margin-left: 35px !important;
    margin-bottom: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: left !important;
    width: 100% !important;
    margin: 0 auto !important;
    margin-top: 0px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    width: 100% !important;
    float: right !important;
    right: 0;
    top: 0;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    width: 100% !important;
    float: right !important;
    right: 0;
    top: 0;
  }

}

@media (max-width: 500px) {
  .ps-timeline-sec .container ol.ps-timeline {
    margin-left: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    height: 215px;
    float: none !important;
    width: auto !important;
}
.ps-timeline-sec .container ol.ps-timeline li.psTimeline.psTimne {
  height: 90px;
}

}



.ps-timeline-sec .container ol:before {
  display: none;
  background: #333;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  left: 8px;
  top: 49.5%;
}
.ps-timeline-sec .container ol:after {
  background: transparent;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  right: 8px;
  top: 49.5%;
}
.ps-timeline-sec .container ol.ps-timeline {
  margin: 30% 0 32%;
  padding: 0;
  border-top: 2px solid transparent;
  
}
.ps-timeline-sec .container ol.ps-timeline::after {
  content: url("../../assets/images/pathtrcs2.svg");
  position: absolute;
  top: -66px;
  width: 100%;
}




.ps-timeline-sec .container ol.ps-timeline li {
  float: left;
  width: 25%;
  padding-top: 30px;
  position: relative;
}
.ps-timeline-sec .container ol.ps-timeline li span {
  margin-left: -25px;
  background: #fff;
  border: 0px solid #333;
  border-radius: 25px;
  box-shadow: 0 0 0 0px #fff;
  text-align: center;
  color: #eb3239;
  font-size: 17px;
  font-style: normal;
  position: absolute;
  top: -14px;
  left: 50%;
  font-family: 'Rajdhani', sans-serif;
  padding: 0 6px 0;
  z-index: 9999;
  font-weight: bold;
  border: 2px dotted #f1f1f1;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
  content: "";
  color: #333;
  width: 0px;
  height: 50px;
  background: #333;
  position: absolute;
  top: -50px;
  left: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
  content: "";
  color: #333;
  width: 8px;
  height: 8px;
  background: #333;
  position: absolute;
  bottom: 90px;
  left: 44%;
  border-radius: 100%;
  display: none;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
  content: "";
  color: #333;
  width: 0px;
  height: 50px;
  background: #333;
  position: absolute;
  bottom: -50px;
  left: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
  content: "";
  color: #333;
  width: 8px;
  height: 8px;
  background: #333;
  position: absolute;
  top: 90px;
  left: 44%;
  border-radius: 100%;
  display: none;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
  position: absolute;
  width: 66%;
  right: -9%;
  left: 0;
  bottom: -27px;
  margin: 0 auto 18px;
  z-index: 999;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
  position: absolute;
  width: 70%;
  right: 0;
  left: -43%;
  margin: -134px auto 0;
  z-index: 99;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li p {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-family: 'Rajdhani', sans-serif;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-top {
  position: absolute;
  margin-bottom: 135px;
  background: #fff;
  padding: 13px 15px 11px 17px;
  border-radius: 5%;
  border: 5px solid #fff;
  box-shadow: 6px 5px 7px #e7e5e5;
  bottom: 0;
  left: 25px;
}




.ps-timeline-sec .container ol.ps-timeline li .ps-bot {
  position: absolute;
  margin-top: 15px;
  background: #fff;
  padding: 13px 15px 11px 17px;
  border-radius: 5%;
  border: 5px solid #fff;
  box-shadow: 6px 5px 7px #e7e5e5;
}



@media (max-width: 500px) {
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot{
    padding: 0;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top{
    padding: 0;
  }

  .ps-timeline-sec .container ol.ps-timeline li .ps-top{
    background: transparent !important;
    border: 0px solid #fff !important;
    box-shadow: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot{
    background: transparent !important;
    border: 0px solid #fff !important;
    box-shadow: none !important;
  }

  .ps-timeline-sec .container ol.ps-timeline li .ps-bot{
    margin-top: 0;

  }

  .ps-timeline-sec .container ol.ps-timeline li .ps-top{
    position: relative;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    padding-top: 0;
  }


.ps-timeline-sec .container ol.ps-timeline li .ps-top {
  margin-bottom: 0;
  left: 0;
}
.ps-timeline-sec .container ol.ps-timeline li span{
  margin-left: 0;
}
.ps-sp-bot.top2 {
  top: -40px !important;
}
.ps-sp-bot.top4 {
  top: -40px !important;
}
.ps-bot ul li {
  text-align: justify;
}
.ps-top ul li {
  text-align: justify;
}
}


.ps-bot ul li {
  float: none !important;
  width: 100% !important;
  padding-top: 0 !important;
  font-family: Rajdhani,sans-serif;
  font-size: 15px;
  font-weight: 500;
  list-style: disc;
}

.ps-top ul li {
  float: none !important;
  width: 100% !important;
  padding-top: 0 !important;
  font-family: Rajdhani,sans-serif;
  font-size: 15px;
  font-weight: 500;
  list-style: disc;
  
}

@media only screen and (max-width: 767px){
.ps-bot ul li {
  float: none !important;
  width: 100% !important;
  padding-top: 0 !important;
  font-family: Rajdhani,sans-serif;
  font-size: 15px;
  font-weight: 500;
  list-style: disc;
  height: auto !important;
}
.ps-top ul li {

  height: auto !important;
}
}

@media only screen and (max-width: 767px){
.ps-timeline-sec .container ol.ps-timeline::after{
  display: none;
}


.ps-timeline-sec .container ol.ps-timeline li .img-handler-top{
  display: none;
}


.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot{
  display: none;
}
}

/* .demo{ background: #444; }
.main-timeline20{ position: relative; }
.main-timeline20:before,
.main-timeline20:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.main-timeline20:before{
    content: "";
    width: 100%;
    height: 5px;
    background: #fff;
    margin: auto 0;
    position: absolute;
    top: 50%;
    left: 0;
}
.main-timeline20 .timeline{
    width: 22%;
    float: left;
    margin-left: 3%;
    position: relative;
}
.main-timeline20 .timeline:before{
    content: attr(data-heading);
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    transform: rotate(-90deg);
    position: absolute;
    top: 30px;
    left: -2px;
    z-index: 2;
}
.main-timeline20 .timeline:after{
    content: "";
    width: 5px;
    height: 60px;
    border-left: 9px dotted #fff;
    position: absolute;
    top: 54%;
    left: 71px;
}
.main-timeline20 .timeline-content{
    padding: 15px 0 40px 50px;
    margin-bottom: 80px;
    border-radius: 15px;
    border: 5px solid #fff;
    text-align: left;
    background: #f67420;
    z-index: 1;
    position: relative;
}
.main-timeline20 .timeline:nth-child(even) .timeline-content{ background: #43a6ba; }
.main-timeline20 .timeline-content:before{
    content: "";
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #d66215;
    border-radius: 10px 0 0 10px;
}
.main-timeline20 .timeline:nth-child(even) .timeline-content:before{ background: #1784ab; }
.main-timeline20 .timeline-content:after{
    content: "";
    width: 40px;
    height: 40px;
    background: #f67420;
    border-bottom: 5px solid #fff;
    border-right: 5px solid #fff;
    position: absolute;
    bottom: -23px;
    left: 50px;
    transform: rotate(45deg);
    z-index: -1;
}
.main-timeline20 .timeline:nth-child(even) .timeline-content:after{ background: #43a6ba; }
.main-timeline20 .description{
    font-size: 14px;
    color: #fff;
}
.main-timeline20 .read-more{
    display: inline-block;
    padding-right: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: capitalize;
    position: relative;
}
.main-timeline20 .read-more:before{
    content: "\f0da";
    font-family: "Font Awesome 5 Free"; font-weight: 900;
    font-size: 24px;
    position: absolute;
    top: -6px;
    right: 0;
}
.main-timeline20 .year{
    display: inline-block;
    width: 85px;
    height: 85px;
    line-height: 85px;
    background: #f67420;
    border-radius: 5px;
    border: 5px solid #fff;
    text-align: center;
    margin-left: 34px;
    transform: rotate(45deg);
    position: relative;
}
.main-timeline20 .timeline:nth-child(even) .year{ background: #43a6ba; }
.main-timeline20 .year span{
    display: block;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    transform: rotate(-45deg);
}
.main-timeline20 .timeline.bottom{ margin: -7.7% 0 0 7%; }
.main-timeline20 .timeline.bottom:nth-child(5n+1),
.main-timeline20 .timeline.bottom:last-child{ margin-left: 3%; }
.main-timeline20 .timeline.bottom:before{
    top: auto;
    bottom: 110px;
}
.main-timeline20 .timeline.bottom:after{
    top: auto;
    left: auto;
    bottom: 54%;
    right: 71px;
    border-color: #fff;
}
.main-timeline20 .timeline.bottom .timeline-content{
    margin: 80px 0 0 0;
    padding: 15px 0 40px 50px;
}
.main-timeline20 .timeline.bottom .timeline-content:after{
    border: none;
    border-top: 5px solid #fff;
    border-left: 5px solid #fff;
    bottom: auto;
    top: -23px;
    left: auto;
    right: 50px;
}
.main-timeline20 .timeline.bottom .year{
    top: 4px;
    margin-left: 132px;
}
@media only screen and (max-width: 1199px){
    .main-timeline20:before{
        width: 5px;
        height: 100%;
        top: 0;
        left: 5%;
    }
    .main-timeline20 .timeline,
    .main-timeline20 .timeline.bottom,
    .main-timeline20 .timeline.bottom:nth-child(5n+1),
    .main-timeline20 .timeline.bottom:last-child{
        width: 85%;
        float: none;
        margin: 0 0 20px 15%;
    }
    .main-timeline20 .timeline:after,
    .main-timeline20 .timeline.bottom:after{
        width: 60px;
        height: 5px;
        top: 50px;
        left: -58px;
        border-top: 9px dotted #fff;
    }
    .main-timeline20 .timeline-content,
    .main-timeline20 .timeline.bottom .timeline-content{ margin: 0; }
    .main-timeline20 .year,
    .main-timeline20 .timeline.bottom .year{
        width: 65px;
        height: 65px;
        line-height: 60px;
        margin: 0;
        position: absolute;
        top: 20px;
        left: -15.5%;
        z-index: 1;
    }
    .main-timeline20 .timeline .timeline-content:after{ display: none; }
    .main-timeline20 .timeline.bottom:before{
        bottom: auto;
        top: 30px;
        left: -2px;
    }
}
@media only screen and (max-width: 990px){
    .main-timeline20 .year,
    .main-timeline20 .timeline.bottom .year{
        left: -16.8%;
    }
}
@media only screen and (max-width: 767px){
    .main-timeline20 .timeline:after,
    .main-timeline20 .timeline.bottom:after{ display: none; }
    .main-timeline20 .year,
    .main-timeline20 .timeline.bottom .year{
        width: 30px;
        height: 30px;
        border: 3px solid #fff;
        left: -13.5%;
    }
    .main-timeline20 .year span{ display: none; }
}
@media only screen and (max-width: 480px){
    .main-timeline20 .year,
    .main-timeline20 .timeline.bottom .year{ left: -15.5%; }
}

 */







/* .ps-timeline-sec {
    position: relative;
    background: #f1f1f1;
  }
  .ps-timeline-sec .container {
    position: relative;
    max-width: 100%;
  }

.img-handler-top:hover{
  content: url('../../assets/images/placeholder2.png');
  position: absolute;
  right:0;
  z-index: 99;
}

.img-handler-bot:hover{
  content: url('../../assets/images/placeholder2.png');
  position: absolute;
  right:0;
  z-index: 99;
}

  @media screen and (max-width: 767px) {
    .ps-timeline-sec .container ol:before {
      background: #333;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: 130px !important;
      left: 36px !important;
      display: none;
    }
    .ps-timeline-sec .container ol:after {
      background: #333;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: inherit !important;
      left: 36px;
      display: none;
    }
    .ps-timeline-sec .container ol.ps-timeline {
      margin: 0px 0 !important;
      border-left: 2px solid #080808;
      padding-left: 0 !important;
      padding-top: 120px !important;
      border-top: 0 !important;
      margin-left: 25px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li {
      height: 220px;
      float: none !important;
      width: inherit !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:nth-child(2) .img-handler-bot img {
      width: 70px;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child {
      margin: 0;
      bottom: 0 !important;
      height: 120px;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
      bottom: 40px !important;
      width: 40% !important;
      margin-left: 25px !important;
      margin-top: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot img {
      width: 100%;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-top {
      margin-bottom: 0 !important;
      top: 0;
      width: 100% !important;

    }
    .ps-timeline-sec .container ol.ps-timeline li span {
      left: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
      position: absolute !important;
      bottom: 150px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
      display: none;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
      margin: 0 auto !important;
      width: 80% !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
      position: absolute !important;
      bottom: 115px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
      display: none;
    }
    .ps-timeline-sec .container ol.ps-timeline li p {
      text-align: left !important;
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      width: 97% !important;
      float: right !important;
      right: 0;
      top: 0;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
      width: 97% !important;
      float: right !important;
      right: 0;
      top: -40px;
    }
  }
  
  
  
  
  
  @media screen and (min-width: 767px) and (max-width: 1024px){
.ps-timeline-sec .container ol.ps-timeline {
    margin: 0px 0 !important;
    border-left: 2px solid #080808;
    padding-left: 0 !important;
    padding-top: 120px !important;
    border-top: 0 !important;
    margin-left: 25px !important;
}}
  
  
  
  
  
  
  .ps-timeline-sec .container ol:before {
    background: #eb3239;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    left: 8px;
    top: 49.5%;
  }
  .ps-timeline-sec .container ol:after {
    background: #eb3239;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    right: 8px;
    top: 49.5%;
  }
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 321px 0;
    padding: 0;
    border-top: 2px solid #eb3239;
    list-style: none;
  }

 






  .ps-timeline-sec .container ol.ps-timeline li {
    float: left;
    width: 25%;
    padding-top: 30px;
    position: relative;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    margin-left: -25px;
    background: #4b4b4d;
    box-shadow: 0 0 0 0px #fff;
    text-align: center;
    color: #fafafa;
    font-size: 1.5vw;
    font-style: normal;
    position: absolute;
    top: -26px;
    left: 50%;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;
    padding: 1px 3px;
    border-radius: 6px;
  }

  @media screen and (max-width: 767px){
    .ps-timeline-sec .container ol.ps-timeline li span {
      font-size: 9.5vw;
      top: -80px;
    }
  }

  
  @media screen and (max-width: 767px){
    .ps-timeline-sec .container ol.ps-timeline li.img-hanrbot:last-child {
      margin-top: 7rem;
    }
  }


  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: "";
    color: #eb3239;
    visibility:hidden;
    width: 2px;
    height: 50px;
    background: #eb3239;
    position: absolute;
    top: -50px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    content: "";
    color: #eb3239;
    width: 8px;
    height: 8px;
    background: #eb3239;
    position: absolute;
    bottom: 90px;
    left: 44%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: "";
    color: #eb3239;
    width: 2px;
    height: 50px;
    background: #eb3239;
    visibility: hidden;
    position: absolute;
    bottom: -50px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    content: "";
    color: #eb3239;
    width: 8px;
    height: 8px;
    background: #eb3239;
    visibility: hidden;
    position: absolute;
    top: 90px;
    left: 44%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 130px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    display: table;
    margin: 0 auto;
    border-radius: 50px;
    position: relative;
    left: 0;
  }

  @media (max-width: 768px){
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
      position: relative;
      left: 11px;
    }
  }


  @media (min-width: 1600px){
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
      position: relative;
      left: 11px;
    }
  }




  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute;
    width: 70px !important;
      left: 0;
      right: 0;
      margin: 60px auto 0;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
    display: table;
    margin: 0 auto;
    border-radius: 50px;
    position: relative;
    left:0;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    position: absolute;
    margin-top: 35px;
  }


  .ps-bot .fortokens {
    height: auto;
    padding: 0;
    background-color: #fff !important;
    border-radius: 15px;
    padding: 2px 14px;
  }

  .ps-top .fortokens {
    height: auto;
    padding: 0;
    background-color: #fff !important;
    border-radius: 15px;
    padding: 2px 14px;
  }


  .fortokens .bilsimg {
    height: auto;
    padding: 15px 0px 0 20px;
    margin-bottom: 15px;
    font-size: 1.2vw;
    text-transform: capitalize;
    color: #080808;
    letter-spacing: 0px;
    text-align: left;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;
  
  }

  .fortokens .bilsimg li{
    list-style: disc;
  }
  .fortokens .bilsimg li::marker{
    text-align: right !important;
    text-align-last: right !important;
  }

  .fortokens ul li{
    width: 100% !important;
    padding-top: 0 !important;
    float: none !important;
    text-align: left !important;
    color: #333 !important;
  }

  .img-handler-top {
    width: 70px !important;
    left: 0;
    right: 0;
    margin: 0 auto 130px;
}
  .img-handler-top img{
    max-width: 100%;
  }

  @media (max-width: 768px){
    .fortokens .bilsimg {
      height: auto;
      padding: 15px 20px;
      margin-bottom: 15px;
      font-size: 3.5vw;
      text-transform: capitalize;
      color: #080808;
      letter-spacing: 0px;
      text-align: left;
      font-family: 'Rajdhani', sans-serif;
      font-weight: 500;
  
    }
  }
   */
   .tabsNames {
    padding-top: 55px;
    position: relative;
    float: left;
    width: 100%;
}

   .tabsBoxes {
    margin-top: 55px;
    position: relative;
    float: left;
    width: 100%;
}
   .tabsNames {
    padding-top: 55px;
      }
   @media (max-width: 820px){
   .tabsBoxes {
 margin-top: 188px;
 margin-bottom: 45px;
   }
   .coinmics3.coinx {
    margin-bottom: 30px !important;
}
  }


  @media (max-width: 500px){
    .tabsBoxes {
  margin-top: 55px;
  margin-bottom:0;
    }}
  .tabsNames button{
    background-color: #fff;
    color: #333;
    font-weight: 500;
    font-size: 23px;
    margin-right: 2px;
    border-radius: 5px;
  
  }

  .tabsNames button.active{
    background-color: #eb3239;
    color: #fff;
    font-weight: 500;
    font-size: 23px;
    margin-right: 2px;
    border-radius: 5px;
  }